import axios from "axios";
import { useEffect, useState } from "react";

const useAssistant = (user) => {
  const [assistant, setAssistant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userEmail = user?.email;
    if (userEmail) {
      setIsLoading(true); // Ensure loading state is updated when fetching starts
      axios
        .get(`${process.env.REACT_APP_BACKEND_API}/users/roles/${userEmail}`)
        .then((res) => {
          setAssistant(res.data.isAssistant);
        })
        .catch((error) => {
          console.error("Error fetching assistant status:", error);
          setAssistant(false); // Ensure it defaults to false if an error occurs
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [user?.email]); // Depend only on userEmail

  return [assistant, isLoading];
};

export default useAssistant;
