import axios from "axios";

const asmaulhusnaCompletion = (batch, sn, new_completion) => {
  const data = {
    new_completion: new_completion,
  };
  console.log(new_completion);
  axios
    .put(
      `${process.env.REACT_APP_BACKEND_API}/asmaulhusna/completion/update/${batch}/${sn}`,
      data
    )
    .then((data) => {});
  return;
};

export default asmaulhusnaCompletion;
