import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";
import React, { useEffect, useState } from "react";
import getDate from "../../../utils/getDate";

import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import gem from "../../../../assets/trophy/gem_blue.png";
import auth from "../../../../firebase.init";
import useAdmin from "../../../hooks/useAdmin";
import useAssistant from "../../../hooks/useAssistant";
import Loading from "../../../Loading/Loading";
import duaCompletion from "./Functions/duaCompletion";
import duaGemsUpdater from "./Functions/duaGemsUpdater";
import getReportUpdate from "./Functions/getReportUpdate";

const DuaAdminAccessRow = ({
  student,
  batch,
  module_no,
  updateSignal,
  setUpdateSignal,
}) => {
  const [user, loading, error] = useAuthState(auth);
  const [admin] = useAdmin(user);
  const [assistant] = useAssistant(user);

  const [bloop, setBloop] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const navigate = useNavigate();

  const loadDetails = (e) => {
    navigate(`/dua-${batch}/${student?.sn}`, {
      state: { id: 1, batch: batch, student: student },
    });
  };

  useEffect(() => {
    setClickCount(0);
    const date = getDate(new Date());
    if (student?.todaysinfo?.date < date) {
      const data = {
        date: date,
      };
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}/dua/date/update/${batch}/${student?.sn}`,
          data
        )
        .then((data) => {
          // update_profile(true);
        });
    }
  }, [student, module_no]);

  const updateModule = async () => {
    const update_module = student?.dua[module_no];

    if (!admin && !assistant) return;
    if (update_module === 1) return;
    if (bloop) return;
    setClickCount(clickCount + 1);

    if (clickCount === 0) {
      if (student?.todaysinfo?.dua === 0) {
        await setBloop(true);
        await getReportUpdate("dua", batch, student?.sn);
        await duaGemsUpdater(student?.gems, batch, student?.sn);
      }
    }
    const data = {
      index: module_no,
    };
    // console.log("completion", completion);

    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/dua/dua_update/${batch}/${student?.sn}`, //http://localhost:5000
        data
      )
      .then((data) => {
        duaCompletion(batch, student?.sn);
        setUpdateSignal(updateSignal + 1);
      });
    await setBloop(false);
    toast.success(`${student?.name.toUpperCase()} Updated!`);
  };

  if (!student) {
    return <Loading></Loading>;
  }

  if (bloop) {
    console.log("blooping");
  }
  return (
    <tr className="hover">
      <th>{student?.sn}</th>
      <td>{student?.name?.toUpperCase()}</td>
      <td>
        {!student?.dua[module_no] && !clickCount && (
          <FontAwesomeIcon
            className="text-2xl text-blue-600 cursor-pointer"
            icon={faCheckCircle}
            onClick={updateModule}
          ></FontAwesomeIcon>
        )}
      </td>

      <td>
        {/* <FontAwesomeIcon icon={faUser} /> */}
        <FontAwesomeIcon
          icon={faAddressCard}
          onClick={loadDetails}
          className="text-2xl text-green-600 cursor-pointer"
        />
      </td>
      <td className="flex">
        {student?.gems}{" "}
        <img
          className={`ml-2 w-4 ${bloop ? "animate-bounce" : ""}`}
          src={gem}
          alt="diamond icon"
        />
      </td>
    </tr>
  );
};

export default DuaAdminAccessRow;
