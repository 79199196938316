import { signOut } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import auth from "../../firebase.init";
import Loading from "../Loading/Loading";
import useAssistant from "./../hooks/useAssistant";

const RequireAssistant = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const [assitant, isLoading] = useAssistant(user);

  const location = useLocation();

  if (loading || isLoading) {
    return <Loading></Loading>;
  }

  if (!user || !assitant) {
    signOut(auth);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAssistant;
