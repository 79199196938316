import React, { useState } from "react";
import getUniqueKeywords from "./getUniqueKeywords";

export default function TelegramMenu({ chat, setSelectedId }) {
  const users = getUniqueKeywords(chat);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleReceiveMessage = (user) => {
    const userId = user?.message?.from?.id;
    setSelectedId(userId);
    setSelectedUserId(userId);
  };

  return (
    <div>
      <ul className="bg-base-200 text-base-content w-80 p-4">
        {users.map((user, index) => {
          const userId = user?.message?.from?.id;
          return (
            <li key={index}>
              <span
                className={`flex justify-between items-center cursor-pointer my-1 p-1 rounded 
                  ${
                    selectedUserId === userId
                      ? "bg-yellow-200 "
                      : "hover:bg-slate-300"
                  }`}
                onClick={() => handleReceiveMessage(user)}
              >
                <span>
                  {index + 1} {" : "}
                  {user?.message?.from?.first_name}
                  <span className="opacity-0">{" : "}</span>
                </span>

                <span
                  className={`text-xs badge ${
                    user?.message?.chat?.type === "private"
                      ? "badge-success"
                      : "badge-warning"
                  }`}
                >
                  {userId}
                </span>
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
