import { faCircleCheck, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function AqeedahLevel1Card() {
  return (
    <div className="text-center rounded shadow-lg px-5 py-12 bg-gradient-to-tl from-sky-300 to-red-100 level_info_card">
      <p className="text-2xl">লেভেল ১</p>
      <p className="text-3xl my-3 text-rose-600">১৬০০ ৳</p>
      <div className="text-left px-5">
        <p className="mb-5">
          <FontAwesomeIcon className="text-yellow-400 pr-2" icon={faWarning} />
          কোর্স করার শর্ত/যোগ্যতাঃ নেই। ১৫ বছরের বেশি বয়সী যে কেউ এই কোর্সে
          ভর্তি হতে পারবে।
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          ৩ মাসের কোর্স
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          দাওয়াহ সেন্টারের সিলেবাস (লেভেল ১)
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          প্রতি ক্লাসে অডিও লেকচার, টেক্সট
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          টেলিগ্রাম গ্রুপে ক্লাস ও প্রশ্নত্তোর
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          পুরুষ - মহিলা আলাদা গ্রুপ
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          সপ্তাহে ৩ দিন ক্লাস
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          ক্লাসটেস্ট ও মডেল টেস্ট
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          বিষয়ভিত্তিক বক্তৃতা প্রতিযোগিতা (১টি)
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          ৩টি সেমিস্টার পরীক্ষা
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          সেরা ১০ জনকে পুরষ্কার
        </p>
        <p>
          <FontAwesomeIcon
            className="text-green-700 pr-2"
            icon={faCircleCheck}
          />
          সার্টিফিকেট
        </p>
      </div>
    </div>
  );
}
