import React, { useEffect, useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { formatDateTime } from "./../../../utils/submissonDateTime";

const s_DateTime = formatDateTime();
const initialFormContent = {
  submissionDateTime: s_DateTime,
  sn: "",
  name: "",
  completion: "",
  points: "",
  course: "",
};

export default function ExportAttendace({ students_record, course }) {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState(initialFormContent);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!students_record || students_record.length === 0) return; // Avoid running the effect if chat is empty

    const records = students_record.map((u) => {
      return {
        submissionDateTime: s_DateTime,
        sn: u?.sn,
        name: u?.name,
        completion: u?.completion,
        points: u?.gems,
        course: course,
      };
    });

    setData(records);
  }, [students_record]);

  const app_script_api =
    "https://script.google.com/macros/s/AKfycbwjD2W3ywPRWlosRByqeQOKq1NF3L8KhN0IlD5c-cec_kokYbEcRrXCXeSIYrmFAlzl/exec";

  const exportReport = async () => {
    if (!data || data.length === 0) {
      toast.error("No data to export!");
      return;
    }

    setIsLoading(true);

    const formDatab = new FormData();
    formDatab.append("data", JSON.stringify(data)); // Send the entire array

    fetch(app_script_api, {
      method: "POST",
      body: formDatab,
    })
      .then((response) => response.text())
      .then((res) => {
        console.log("Success:", res);
        setIsLoading(false);
        toast.success("Data exported successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        toast.error("Error exporting report!");
      });
  };

  return (
    <div
      className="inline-block cursor-pointer p-1 bg-orange-400 rounded"
      onClick={exportReport}
      disabled={isLoading}
    >
      <MdFileUpload className={isLoading ? `animate-bounce` : ""} />
    </div>
  );
}
