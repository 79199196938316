import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResultPageSkeleton from "../../../Loading/ResultPageSkeleton";
import digitConverter from "../../../utils/digitConverter";
import thConverter from "../../../utils/thConverter";
import NoDataFoundModal from "../../Aqeedah/NoDataFoundModal";
import AsmaulHusnaResultPageRow from "./AsmaulHusnaResultPageRow";

const AsmaulHusnaResultPage = () => {
  const params = useParams();
  const [students, setStudents] = useState([]); // Store students locally
  const [filteredStudents, setFilteredStudents] = useState([]); // For search results
  const [loading, setLoading] = useState(true);

  const batchNo = parseInt(params.batch);

  // Fetch student data only once
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/asmaulhusna/${batchNo}`
        );
        setStudents(data); // Store students in state
        setFilteredStudents(data); // Initially, show all students
        setLoading(false);
      } catch (error) {
        console.error("Error fetching student data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [batchNo]);

  // Handle search input and filter students
  const searchByName = (e) => {
    e.preventDefault();
    const name = e.target.name.value.toLowerCase();

    const filtered = students.filter((student) =>
      student.name.toLowerCase().includes(name)
    );

    if (filtered.length === 0) {
      document.getElementById("my_modal_3").showModal();
      return;
      // setNoDataModalVisible(true);
    }
    setFilteredStudents(filtered); // Update the filtered list
  };

  if (loading) {
    // return <Loading />;
    return (
      <div className="my-10">
        <p className="text-center text-2xl">
          আল আসমাউল হুসনা কোর্সের {digitConverter(batchNo)}
          {thConverter(batchNo)} ব্যাচে স্বাগতম!
        </p>
        <ResultPageSkeleton />
      </div>
    );
  }

  return (
    <div className="my-10">
      <p className="text-center text-2xl">
        আল আসমাউল হুসনা কোর্সের {digitConverter(batchNo)}
        {thConverter(batchNo)} ব্যাচে স্বাগতম!
      </p>

      <div className="text-center">
        <form onSubmit={searchByName}>
          <div className="form-control my-10">
            <div className="input-group flex justify-center">
              <input
                name="name"
                type="text"
                placeholder="আপনার নাম লিখে সার্চ করুন"
                className="input input-bordered mr-2 w-4/5"
              />
              {
                <button type="submit" className="btn btn-primary btn-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              }
            </div>
          </div>
        </form>
      </div>

      <div className="overflow-x-auto w-10/12 mx-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>SN</th>
              <th>Name</th>
              <th>Father's Name</th>
              <th>Gems</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {filteredStudents?.map((student) => (
              <AsmaulHusnaResultPageRow
                key={student.sn}
                student={student}
                batch={batchNo}
              ></AsmaulHusnaResultPageRow>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <NoDataFoundModal></NoDataFoundModal>
      </div>
    </div>
  );
};

export default AsmaulHusnaResultPage;
