import React from "react";
import { FaLink } from "react-icons/fa";

const group_link = (level, gender) => {
  const links = {
    1: {
      male: "https://t.me/+tzqfpH0sJwJlMjFk",
      female: "https://t.me/+xjadvzwVawk4NGY0",
    },
  };

  return links?.[level]?.[gender] || "Link not found";
};

export default function AsmaulHusnaRegGroup({ gender, level = 1 }) {
  return (
    <a
      href={group_link(level, gender)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className={`relative overflow-hidden md:order-2 w-56 h-28 rounded py-2 px-5 my-3 md:my-0 bg-gradient-to-tl ${
          gender == "male"
            ? "from-cyan-200 to-cyan-100 text-cyan-600"
            : "from-pink-200 to-pink-100 text-pink-600"
        }  payment-card`}
      >
        <div className="flex justify-between">
          <p className="">
            LEVEL {level} : {gender.toUpperCase()}
          </p>
          <FaLink className={`text-2xl  ml-auto animate-pulse`} />
        </div>

        <FaLink className="text-8xl absolute -ml-6 mt-1 opacity-10 text-inherit " />

        <p className={`text-center font-bold text-xl my-2  `}>
          <span className="uppercase font-bold text-2xl">গ্রুপ লিংক</span>
        </p>
      </div>
    </a>
  );
}
