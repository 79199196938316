import React from "react";
import CardRamadanNextBatch from "./CardRamadanNextBatch";
import RamadanPayment from "./RamadanPayment";
import RamadanRegGroup from "./RamadanRegGroup";
import RamadanRegistrationForm from "./RamadanRegistrationForm";

const info = {
  gender: "",
  level: "",
  payment: "",
};

export default function RamadanRegistration() {
  return (
    <section>
      <div className="container mx-auto my-10 px-5">
        <p className="text-xl text-blue-600 mt-10 mb-5 text-center">
          রমাদান ও সিয়াম কোর্সের ৪র্থ ব্যাচে ভর্তি শুরু হয়েছে!
        </p>

        <div className="mx-auto my-5 text-center ">
          <ul className="steps steps-vertical">
            <li className="step step-primary">
              <a href="#send-money">৫০০ টাকা সেন্ডমানি করুন</a>
            </li>
            <li className="step step-primary">
              <a href="#reg-form">ভর্তি ফর্ম পূরন করুন</a>
            </li>
            <li className="step step-primary">
              <a href="#group-link">টেলিগ্রাম গ্রুপে জয়েন করুন</a>
            </li>
          </ul>
        </div>
        <RamadanRegistrationForm />

        <div>
          <p className="text-xl text-blue-600 mt-24">গ্রুপে জয়েন করুন</p>
          <p>
            আপনার পেমেন্ট চেক করে গ্রুপে অ্যাড করা হবে। পেমেন্ট চেক করতে সময়
            লাগতে পারে।
          </p>
          <p className="mb-5">
            আপনার কাছে টাকা পাঠানোর (সেন্ডমানি) ছবি বা স্ক্রিনশট থাকলে ইনবক্সে
            দিবেন। তাহলে দ্রুত গ্রুপে অ্যাড করা সম্ভব হবে।
          </p>
          <p>মহিলাদের গ্রুপ</p>
          <RamadanRegGroup gender={"female"} level={1} />
          <br />
          <p>পুরুষদের গ্রুপ</p>
          <RamadanRegGroup gender={"male"} level={1} />
        </div>

        <section>
          <p className="text-xl text-blue-600 mt-24">সেন্ডমানি করুন</p>
          <RamadanPayment />
        </section>
        <CardRamadanNextBatch />
      </div>
    </section>
  );
}
