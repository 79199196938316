import React from "react";
import AsmaulHusnaPayment from "./AsmaulHusnaPayment";
import AsmaulHusnaRegGroup from "./AsmaulHusnaRegGroup";
import AsmaulHusnaRegistrationForm from "./AsmaulHusnaRegistrationForm";

const AsmaulHusnaRegistration = () => {
  const male_group_link = "https://t.me/+oNrbuUtw_vxmZDdk";
  const female_group_link = "https://t.me/+qKeeqrhcQ7dlMjVk";

  return (
    <section>
      <div className="container mx-auto my-10 px-5">
        <p className="text-xl text-blue-600 mt-10 mb-5 text-center">
          আসমাউল হুসনা কোর্সের ১ম ব্যাচে ভর্তি শুরু হয়েছে!
        </p>

        <div className="mx-auto my-5 text-center ">
          <ul className="steps steps-vertical">
            <li className="step step-primary">
              <a href="#send-money">৫০০ টাকা সেন্ডমানি করুন</a>
            </li>
            <li className="step step-primary">
              <a href="#reg-form">ভর্তি ফর্ম পূরন করুন</a>
            </li>
            <li className="step step-primary">
              <a href="#group-link">টেলিগ্রাম গ্রুপে জয়েন করুন</a>
            </li>
          </ul>
        </div>
        <AsmaulHusnaRegistrationForm />

        <div>
          <p className="text-xl text-blue-600 mt-24">গ্রুপে জয়েন করুন</p>
          <p>
            আপনার পেমেন্ট চেক করে গ্রুপে অ্যাড করা হবে। পেমেন্ট চেক করতে সময়
            লাগতে পারে।
          </p>
          <p className="mb-5">
            আপনার কাছে টাকা পাঠানোর (সেন্ডমানি) ছবি বা স্ক্রিনশট থাকলে ইনবক্সে
            দিবেন। তাহলে দ্রুত গ্রুপে অ্যাড করা সম্ভব হবে।
          </p>
          <p>মহিলাদের গ্রুপ</p>
          <AsmaulHusnaRegGroup gender={"female"} level={1} />
          <br />
          <p>পুরুষদের গ্রুপ</p>
          <AsmaulHusnaRegGroup gender={"male"} level={1} />
        </div>

        <section>
          <p className="text-xl text-blue-600 mt-24">সেন্ডমানি করুন</p>
          <AsmaulHusnaPayment />
        </section>
        {/* <CardAsmaulHusnaNextBatch /> */}
      </div>
    </section>
  );
};

export default AsmaulHusnaRegistration;
