import getToday from "../../../../utils/getToday";

const getRamadanDate = () => {
  const today = new Date(getToday()); // Convert to Date object
  const ramadanStart = new Date("2025-03-03"); // Start of Ramadan

  const diff = Math.floor((today - ramadanStart) / (1000 * 60 * 60 * 24)) + 1;

  return diff >= 1 && diff <= 25 ? diff : 0;
};

export default getRamadanDate;
