import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const AsmaulHusnaBarchart = ({ data, level }) => {
  return (
    <div className=" bg-gradient-to-tl from-orange-200 to-orange-100 rounded">
      <ResponsiveContainer height={200}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, 100]} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Score" fill="orange" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AsmaulHusnaBarchart;
