import React from "react";
import NoticeBoradCard from "./NoticeBoradCard";

const NoticeBoard = () => {
  return (
    <div className="min-h-screen container mx-auto my-10">
      <p className="text-center text-2xl mb-5">নোটিশ বোর্ডে স্বাগতম!</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-3 md:mx-10">
        <NoticeBoradCard
          course="সহীহ আক্বীদাহ"
          courseLink="/aqeedah"
        ></NoticeBoradCard>

        <NoticeBoradCard
          course="ইসলামী ফিকহ"
          courseLink="/fiqh"
        ></NoticeBoradCard>
        <NoticeBoradCard
          course="সীরাতুন নবী (সা)"
          courseLink="/seerat"
        ></NoticeBoradCard>

        <NoticeBoradCard
          course="তাজউইদ ও কুরআন"
          courseLink="/tajweed"
        ></NoticeBoradCard>

        <NoticeBoradCard
          course="রমাদান ও সিয়াম"
          courseLink="/ramadan-siyam"
        ></NoticeBoradCard>

        <NoticeBoradCard
          course="আসমাউল হুসনা"
          courseLink="/asmaul-husna"
        ></NoticeBoradCard>

        <NoticeBoradCard
          course="সংক্ষিপ্ত তাফসীর"
          courseLink="/tafseer"
        ></NoticeBoradCard>

        <NoticeBoradCard
          course="আরবি ভাষা"
          courseLink="/arabic"
        ></NoticeBoradCard>

        <NoticeBoradCard
          course="দোআ ও যিকির"
          courseLink="/dua"
        ></NoticeBoradCard>
      </div>
    </div>
  );
};

export default NoticeBoard;
