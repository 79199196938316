import React from "react";
import asmaulhusna_channel from "../../../../assets/asmaul_husna/group_logo/channel_logo.png";
import asmaulhusna_female from "../../../../assets/asmaul_husna/group_logo/female_group_logo.png";
import asmaulhusna_male from "../../../../assets/asmaul_husna/group_logo/male_group_logo.png";

import { faHand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AsmaulHusnaGroupCard({ batch, gender, level }) {
  const links = {
    1: {
      male_1: "https://t.me/+tzqfpH0sJwJlMjFk",
      female_1: "https://t.me/+xjadvzwVawk4NGY0",
      channel_1: "https://t.me/+7MzkU8ouBitlNmE8",
    },
  };

  const getLink = (type) => {
    if (links[batch] && links[batch][type]) {
      return links[batch][type];
    } else {
      return "";
    }
  };
  const imageMap = {
    1: {
      male: asmaulhusna_male,
      female: asmaulhusna_female,
      channel: asmaulhusna_channel,
    },
  };

  if (links[batch])
    return (
      <div className="p-5 rounded bg-gradient-to-tl from-orange-200">
        <p>
          <span className="text-xl">Join </span>
          <FontAwesomeIcon className="text-blue-600" icon={faHand} fade />
        </p>

        <div className="mt-4 flex justify-around">
          <a
            href={getLink(`${gender}_${level}`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div>
                <img className="w-20" src={imageMap[level][gender]} alt="" />
              </div>
              <p className="text-center">Class Group</p>
            </div>
          </a>

          <a
            href={getLink(`channel_${level}`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div>
                <img className="w-20" src={imageMap[level]["channel"]} alt="" />
              </div>
              <p className="text-center">Lecture Notes</p>
            </div>
          </a>
        </div>
      </div>
    );
}
