import React, { useEffect, useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { formatDateTime } from "./../utils/submissonDateTime";
import getUniqueKeywords from "./getUniqueKeywords";

const s_DateTime = formatDateTime();
const initialFormContent = {
  submissionDateTime: s_DateTime,
  id: "",
  name: "",
  amount: "",
};

export default function ExportMessages({ chat }) {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState(initialFormContent);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!chat || chat.length === 0) return; // Avoid running the effect if chat is empty

    const users = getUniqueKeywords(chat);
    const conversation = users.map((u) => {
      return {
        submissionDateTime: s_DateTime,
        id: u?.message?.from?.id,
        name: [u?.message?.from?.first_name, u?.message?.from?.last_name]
          .filter(Boolean)
          .join(" "), // Avoid undefined
        message: chat
          .filter((d) => d?.message?.from?.id === u?.message?.from?.id)
          .map((d) => d?.message?.text || "") // Avoid undefined messages
          .join(" "), // Join texts
      };
    });

    setData(conversation);
  }, [chat]);

  const app_script_api =
    "https://script.google.com/macros/s/AKfycbzbgX3ZOVVTtDIY5vM-B1I49gQQ22VknvcmPVJAJBduMY8uYTTpJTY0vXPmRISAuKBN/exec";

  const exportMessage = async () => {
    if (!data || data.length === 0) {
      toast.error("No data to export!");
      return;
    }

    setIsLoading(true);

    const formDatab = new FormData();
    formDatab.append("data", JSON.stringify(data)); // Send the entire array

    fetch(app_script_api, {
      method: "POST",
      body: formDatab,
    })
      .then((response) => response.text())
      .then((res) => {
        console.log("Success:", res);
        setIsLoading(false);
        toast.success("Messages exported successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        toast.error("Error exporting messages!");
      });
  };

  return (
    <span
      className="cursor-pointer p-1 bg-orange-400 rounded"
      onClick={exportMessage}
      disabled={isLoading}
    >
      <MdFileUpload className={isLoading ? `animate-bounce` : ""} />
    </span>
  );
}
