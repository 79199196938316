import { faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getAdmissionDate } from "../../../CourseRegistration/admissionDates";
import calculateRemainingDays from "../../../utils/calculateRemainingDays";
import digitConverter from "./../../../utils/digitConverter";

const CardDuaNextBatch = () => {
  const location = useLocation();
  const onRegistrationPage =
    location.pathname === "/registration-dua" ? true : false;

  const onCoursePage = location.pathname === "/courses/dua" ? true : false;

  const today = new Date();
  const addmissionStartDate = new Date(getAdmissionDate("aqeedah"));

  const data = calculateRemainingDays(addmissionStartDate);

  const m = digitConverter(data.months);
  const d = digitConverter(data.days);

  return (
    <div className="bg-slate-200 p-5 md:p-16 rounded text-white my-10 next_batch_reg_card">
      <div className="mb-10 next_batch_reg_heading">
        <p className="text-center text-sky-900  text-3xl mb-4">
          দোআ ও যিকির কোর্সের ৩য় ব্যাচে ভর্তি চলছে!{" "}
        </p>
        {/* <p className="text-center text-sky-900  text-3xl mb-4">
          দোআ ও যিকির কোর্সের পরবর্তী ব্যাচে ভর্তি শুরু হবে
        </p> */}
        {/* <p className="text-center text-black text-2xl">
          আগামী বছর (২০২৫) এ, ইনশা আল্লাহ।
        </p> */}
        {/* <p className='text-center text-black text-2xl'>আর মাত্র <span className={` ${m==='০' ? 'hidden' : ''}`}>{m} মাস </span> {d} দিন পর!</p>  */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-black text-xl next_batch_reg_text">
        {/* <p className="p-3 bg-green-300 rounded">
          ভর্তি শুরু হবে রমাদানের ২ সপ্তাহ আগে{" "}
        </p> */}
        <p className="p-3 bg-orange-300 rounded">
          ভর্তি শেষ হবে ১ মার্চ, শনিবার
        </p>
        {/* <p className='p-3 bg-blue-300 rounded'>অরিয়েন্টেশন ক্লাস ১ জানুয়ারি</p> */}
        <p className="p-3 bg-red-300 rounded">
          ক্লাস শুরু হবে ২ মার্চ, রবিবার{" "}
        </p>
        {!onCoursePage && (
          <Link to="/courses/dua">
            <p className="p-3 bg-lime-300 rounded shadow-md">
              কোর্স সম্পর্কে বিস্তারিত জানুন{" "}
              <FontAwesomeIcon
                className="text-green-600"
                icon={faMousePointer}
                fade
              />
            </p>
          </Link>
        )}
        {!onRegistrationPage && (
          <Link to="/registration-dua">
            <p className="p-3 bg-green-300 rounded shadow-md">
              এখনি রেজিস্ট্রেশন করুন{" "}
              <FontAwesomeIcon
                className="text-green-600"
                icon={faMousePointer}
                fade
              />
            </p>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CardDuaNextBatch;
