import React from "react";
import ramadan_1_channel from "../../../../assets/ramadan/group_logo/Ramadan_Siyam_logo_Channel_logo.png";
import ramadan_1_female from "../../../../assets/ramadan/group_logo/Ramadan_Siyam_logo_Female_Group_logo.png";
import ramadan_1_male from "../../../../assets/ramadan/group_logo/Ramadan_Siyam_logo_Male_Group_logo.png";

import { faHand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FiqhGroupCard({ batch, gender, level }) {
  const links = {
    4: {
      male_1: "https://t.me/+835gJWDbGLg3YWY8",
      female_1: "https://t.me/+CBGlmlUaN_4wODU0",
      channel_1: "https://t.me/+ITGGJzk6oxQ2ZDE0",
    },
  };

  const getLink = (type) => {
    if (links[batch] && links[batch][type]) {
      return links[batch][type];
    } else {
      return "";
    }
  };
  const imageMap = {
    1: {
      male: ramadan_1_male,
      female: ramadan_1_female,
      channel: ramadan_1_channel,
    },
  };

  if (links[batch])
    return (
      <div className="p-5 rounded bg-gradient-to-tl from-purple-300">
        <p>
          <span className="text-xl">Join </span>
          <FontAwesomeIcon className="text-blue-600" icon={faHand} fade />
        </p>

        <div className="mt-4 flex justify-around">
          <a
            href={getLink(`${gender}_${level}`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div>
                <img className="w-20" src={imageMap[level][gender]} alt="" />
              </div>
              <p className="text-center">Class Group</p>
            </div>
          </a>

          <a
            href={getLink(`channel_${level}`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div>
                <img className="w-20" src={imageMap[level]["channel"]} alt="" />
              </div>
              <p className="text-center">Lecture Notes</p>
            </div>
          </a>
        </div>
      </div>
    );
}
