import axios from "axios";

const asmaulhusnaGemsUpdater = (gems, batch, sn) => {
  const data = {
    gems: gems + 1,
  };
  axios
    .put(
      `${process.env.REACT_APP_BACKEND_API}/asmaulhusna/gems/update/${batch}/${sn}`,
      data
    )
    .then((data) => {
      console.log(data);
    });
  return;
};

export default asmaulhusnaGemsUpdater;
