import React from "react";
import { useNavigate } from "react-router-dom";
import gem from "../../../../assets/trophy/gem_orange.png";
import Loading from "../../../Loading/Loading";

const AsmaulHusnaResultPageRow = ({ student, batch }) => {
  const navigate = useNavigate();
  const loadDetails = (e) => {
    navigate(`${student?.sn}`, {
      state: { id: 1, batch: batch, student: student },
    });
    // <Link to="hi"/>
  };

  if (!student) {
    return <Loading></Loading>;
  }

  return (
    <tr onClick={loadDetails} className="hover">
      <th>{student?.sn}</th>
      <td>{student?.name?.toUpperCase()}</td>
      {student?.fatherName && <td>{student?.fatherName?.toUpperCase()}</td>}
      <td className="flex">
        {student?.gems} <img className="ml-2 w-4" src={gem} />
      </td>
      <td>{student?.phone}</td>
    </tr>
  );
};

export default AsmaulHusnaResultPageRow;
