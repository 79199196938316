import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Progress from "../Loading/Progress";

const StudentNames = () => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false); // ✅ Proper loading state

  const handleJsonUpdate = async (e) => {
    setLoading(true); // ✅ Show progress before fetching

    try {
      const course = e.course;
      const batchNo = parseInt(e.batch);

      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/${course}/${batchNo}`
      );

      setStudents(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // ✅ Hide progress after fetching
    }
  };

  return (
    <section>
      <p className="text-2xl text-center mb-5">Certificate Name Query</p>
      <section className="bg-green-200 p-5 rounded">
        <p className="text-xl mb-5">Get Names</p>

        <form onSubmit={handleSubmit(handleJsonUpdate)}>
          <div className="flex items-end gap-10 mb-5">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Course Name</span>
              </label>
              <input
                type="text"
                placeholder="aqeedah"
                className="input input-bordered w-full max-w-xs"
                {...register("course", { required: true })}
              />
              {errors.course?.type === "required" && "Course Name is required"}
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Batch No</span>
              </label>
              <input
                type="text"
                placeholder="19"
                className="input input-bordered w-full max-w-xs"
                {...register("batch", { required: true })}
              />
              {errors.batch?.type === "required" && "Batch Number is required"}
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Fetching..." : "Fetch"}
            </button>

            <input
              onClick={() => clearErrors()}
              type="reset"
              className="btn btn-error"
            />

            <div
              className="radial-progress text-green-600 font-bold"
              style={{ "--value": students?.length }}
              role="progressbar"
            >
              {students?.length}
            </div>
          </div>
        </form>
      </section>

      {loading ? (
        <div className="my-10">
          <Progress />
        </div> // ✅ Show progress component while loading
      ) : (
        <div className="my-10" id="certificate-student-list">
          {students.length > 0 ? (
            <table>
              <tbody>
                {students.map((student, index) => (
                  <tr key={index}>
                    <td>{student?.sn}</td>
                    <td>
                      <span className="ml-4">
                        {student?.name.toUpperCase()}{" "}
                        {student?.fatherName && (
                          <span>
                            {student?.gender === "female" ? "BINT" : "BIN"}{" "}
                            {student?.fatherName.toUpperCase()}
                          </span>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-500">No students found.</p>
          )}
        </div>
      )}
    </section>
  );
};

export default StudentNames;
