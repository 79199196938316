import React from "react";
import diamond from "../../../../assets/trophy/gem_orange.png";

const AsmaulHusnaDiamond = ({ gems }) => {
  return (
    <div>
      <div className="flex items-center align-center">
        <p className="text-7xl mr-2 text-orange-500">{gems}</p>
        <img
          className={`w-10 ${gems ? "mt-4 animate-bounce" : ""}`}
          src={diamond}
        />
      </div>
    </div>
  );
};

export default AsmaulHusnaDiamond;
